import { AppEnvType, useConfig } from 'FrontRoyalConfig';
import { useClientAsyncInit } from '@statsig/react-bindings';
import { StatsigSessionReplayPlugin } from '@statsig/session-replay';
import { LOAD_STATSIG_CLIENT_TRACING_NAME, startSentryInactiveSpan } from 'ErrorLogging';
import { StatsigAutoCapturePlugin } from '@statsig/web-analytics';
import { useEffect } from 'react';
import { statsigClientProvider } from 'StatsigClientProvider';
import Auid from 'Auid';

export function InitStatsigClient({ children }: { children: React.ReactNode }) {
    const config = useConfig({ suspense: true });
    const statsigClientKey = config.statsigClientKey();
    const options = {
        plugins:
            config.appEnvType() === AppEnvType.production
                ? [new StatsigSessionReplayPlugin(), new StatsigAutoCapturePlugin()]
                : [],
        environment: { tier: config.appEnvType() },
        networkConfig: { api: 'https://app.quantic.edu/statsig/v1' },
    };

    // Since we're using useClientAsyncInit and not waiting for isLoading to be true, the app will
    // render before statsig has loaded data via an http call. Use the useSuspenseFeatureGate hook
    // to prevent parts of the app that need statsig data from rendering until it's available.
    // Note that we're initializing with a simple Statsig user object. The identifyUser call
    // will update with more information and the status ping will keep it up-to-date
    const { client } = useClientAsyncInit(statsigClientKey, { userID: Auid.ensure() }, options);

    useEffect(() => {
        statsigClientProvider.client = client;
        const sentrySpan = startSentryInactiveSpan(LOAD_STATSIG_CLIENT_TRACING_NAME);
        statsigClientProvider.waitForLoadedClient().then(() => {
            sentrySpan.end();
        });
    }, [client]);

    useEffect(() => {
        const shutdownStatsig = () => statsigClientProvider.client?.shutdown();
        window.addEventListener('beforeunload', shutdownStatsig);
        return () => {
            window.removeEventListener('beforeunload', shutdownStatsig);
        };
    }, []);

    // See comment in useSuspenseStatsigClient about why we don't set up a `StatsigProvider` here,
    // even though it seems like that would make sense.
    return children;
}
