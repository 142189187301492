class AngularInjectorProvider {
    injector: angular.auto.IInjectorService | null = null;

    requireInjector() {
        if (!this.injector) {
            throw new Error('Angular injector is not set');
        }
        return this.injector;
    }

    get<T>(name: string): T {
        return this.requireInjector().get<T>(name);
    }

    safeTestGet<T>(name: string): T | null {
        try {
            return this.get<T>(name);
        } catch (e) {
            if (e instanceof Error && e.message === 'Angular injector is not set' && window.RUNNING_IN_TEST_MODE) {
                return null;
            }
            throw e;
        }
    }
}

const angularInjectorProvider = new AngularInjectorProvider();

export { angularInjectorProvider };
