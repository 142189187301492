import { type Cohort } from 'Cohorts';
import { type LessonProgress, type StreamProgress, type ProjectProgress } from 'ContentProgress';
import { formatScore as _formatScore } from 'FormatScore';

const PassingScores = {
    capstone: 3,
    standard: 2,
    presentation: 2,
} as const;

const emdash = '\u2014';

const getPassingProjectScore = (cohort: Cohort, projectType: string) => {
    if (
        projectType === 'standard' &&
        cohort &&
        ((cohort.programType === 'emba' && new Date(cohort.startDate * 1000) <= new Date('2019/07/01')) ||
            (cohort.programType === 'mba' && new Date(cohort.startDate * 1000) <= new Date('2019/07/29')))
    ) {
        return 1;
    }

    return PassingScores[projectType as keyof typeof PassingScores];
};

export const getProjectStatus = (progress: ProjectProgress | undefined, projectType: string, cohort: Cohort) => {
    const passingScore = getPassingProjectScore(cohort, projectType);

    if (!progress) return emdash;

    if (progress.markedAsPassed || progress.waived) return 'P';

    if (progress.score === null || progress.score === undefined) return emdash;

    return progress.score >= passingScore ? 'P' : 'F';
};

function getScoreFromCompletedProgressRecord(progress: LessonProgress | StreamProgress): number | null {
    // lesson progress
    if ('bestScore' in progress) return progress.bestScore;

    // stream progress
    return progress.officialTestScore;
}

function formatScoreOnCompletedProgressRecord(progress: LessonProgress | StreamProgress) {
    const score = getScoreFromCompletedProgressRecord(progress);

    // Waived smartcases (lessons) will have no score, and there are many waived smartcases,
    // so it is expected that we would regularly return a 'P' for smartcases.
    // Completed exams (streams) SHOULD always have a score, even if they are waived, but there are a couple of records in
    // the database with no scores. Those oddballs are the only cases where a stream would return 'P' here.
    if (typeof score !== 'number') return 'P';

    return `${_formatScore(score)}%`;
}

export const formatScore = (progress: LessonProgress | StreamProgress | undefined) => {
    if (!progress || !progress.complete) return emdash;

    return formatScoreOnCompletedProgressRecord(progress);
};
