import { type StatsigClient } from '@statsig/react-bindings';
import { statsigClientProvider } from 'StatsigClientProvider';

type Result = {
    client: StatsigClient;
    checkGate: StatsigClient['checkGate'];
};

export function useSuspenseStatsigClient(): Result {
    // For 10 seconds after initializing the statsig client, we wait until it has loaded
    // data before using it. If 10 seconds pass and we still haven't loaded it, then
    // just use the client with the cached data
    if (!statsigClientProvider.loadedClient && !statsigClientProvider.loadingTimedOut) {
        throw statsigClientProvider.waitForHopefullyLoadedClient({ caller: 'useSuspenseStatsigClient' });
    }

    const client = statsigClientProvider.client;

    // We never expect to get here with no client at all
    if (!client) {
        throw new Error('Statsig client not set in useSuspenseStatsigClient');
    }

    return {
        client,
        checkGate: client.checkGate,
    };
}
