/* eslint-disable max-lines-per-function */
/* eslint-disable no-nested-ternary */
import { type AnyObject } from '@Types';
import { getRequiredStreamPackIdsFromPeriods, getPlaylistPackIds, getFoundationsPlaylistPackId } from 'Cohorts';
import { formattedUserFacingMonthDayYearMedium } from 'DateHelpers';
import { useCurrentUser } from 'FrontRoyalAngular';
import { frontRoyalStoreApi } from 'FrontRoyalStore';
import { type LearnerProject } from 'LearnerProjects';
import { useMemo } from 'react';
import { type Stream } from 'Lessons';
import { isAssessmentPolicyWithCurriculumStarted } from 'RefundEntitlement/utils';
import { getCohort, getRefundEntitlement } from 'Users';
import { useContentProgress } from 'ContentProgress';
import { useSuspenseQuery } from 'ReduxHelpers';
import { type PlaylistsWithGrades } from './Grades.types';
import { formatScore, getProjectStatus } from './utils';

// FIXME: There is a plan to make a ticket to make it clearer on distinguishing
// capstone vs regular projects and this should be updated when that is finished
const isCapstoneProject = (project: LearnerProject) =>
    ['capstone', 'isdp'].some(v => !!project.requirementIdentifier.match(v));

const useGetContent = () => {
    const user = useCurrentUser();
    const cohort = getCohort(user);

    const { data: playlists = [] } = useSuspenseQuery(frontRoyalStoreApi, 'getPublishedPlaylists');
    const { data: courses = [] } = useSuspenseQuery(frontRoyalStoreApi, 'getPublishedStreams');

    // FIXME: move preferStrictSmartcaseScore from schedulable into program type config
    const includeFoundationsInGrades = useMemo(() => (cohort ? cohort.startDate < 1697443200 : false), [cohort]); // < Cycle 56

    const foundationsPlaylist = useMemo(() => {
        if (!cohort) return null;
        const foundationsPlaylistLocalePackId = getFoundationsPlaylistPackId(cohort);
        if (!foundationsPlaylistLocalePackId) return null;

        return playlists.find(p => p.localePackId === foundationsPlaylistLocalePackId) ?? null;
    }, [cohort, playlists]);

    const requiredStreamsPackIds = useMemo(() => (cohort ? getRequiredStreamPackIdsFromPeriods(cohort) : []), [cohort]);

    const playlistsAndSpecializations = useMemo(() => {
        if (!cohort || !playlists.length) return [];

        return getPlaylistPackIds(cohort)
            .filter(packId => (includeFoundationsInGrades ? !!packId : packId !== foundationsPlaylist?.localePackId))
            .map(packId => {
                const playlist = playlists.find(p => p.localePack.id === packId)!;

                return {
                    ...playlist,
                };
            });
    }, [cohort, foundationsPlaylist, includeFoundationsInGrades, playlists]);

    const assessmentCourses = useMemo(
        () => courses.map(c => ({ ...c, lessons: c.lessons.filter(l => l.assessment === true) })),
        [courses],
    );

    const nonPlaylistRequiredCourses = useMemo(() => {
        if (!cohort) return [];

        const playlistStreamPackIds = playlistsAndSpecializations.flatMap(p =>
            p.streamEntries.map(se => se.localePackId),
        );

        const nonPlaylistRequiredPackIds = requiredStreamsPackIds.filter(s => !playlistStreamPackIds.includes(s));

        return courses.filter(c => nonPlaylistRequiredPackIds.includes(c.localePack.id));
    }, [cohort, courses, playlistsAndSpecializations, requiredStreamsPackIds]);

    const projectsAndCapstones = useMemo(
        () =>
            cohort?.periods.reduce(
                ({ projectsByPlaylists, capstones }, period, i) => {
                    const projects = period.learnerProjects;

                    if (projects?.[0]?.requirementIdentifier) {
                        // The playlist for this project is defined as the playlist for the last
                        // required stream before this project in the schedule.
                        const previousPeriodWithRequiredStream = cohort?.periods
                            // We used to look at only periods before the current period when trying to
                            // find a period with a required stream to match up to the project
                            // but the MBA 41-50 Marketing project was in the same period as the
                            // last required stream associated withe the marketing playlist
                            .filter((_, j) => j <= i)
                            .reverse() // in reverse order
                            .find(p => p.streamEntries.find(se => se.required)); // the first one that has a required stream

                        const previousRequiredStreamLocalePackId =
                            previousPeriodWithRequiredStream?.streamEntries?.find(se => se.required)?.localePackId;

                        if (previousRequiredStreamLocalePackId) {
                            const projectStream = courses.find(
                                c => c.localePack.id === previousRequiredStreamLocalePackId,
                            );
                            const projectPlaylist = playlists.find(pl =>
                                pl.streamEntries.find(se => se.localePackId === projectStream?.localePack.id),
                            )!;

                            if (isCapstoneProject(projects[0])) {
                                projects.forEach(p => {
                                    if (!capstones.find(c => c.requirementIdentifier === p.requirementIdentifier)) {
                                        capstones.push(p);
                                    }
                                });
                            } else if (projectPlaylist) {
                                projectsByPlaylists[projectPlaylist.localePackId] = projects;
                            }
                        }
                    }

                    return {
                        projectsByPlaylists,
                        capstones,
                    };
                },
                { projectsByPlaylists: {} as AnyObject<LearnerProject[]>, capstones: [] as LearnerProject[] },
            ) || { projectsByPlaylists: {}, capstones: [] },
        [cohort?.periods, courses, playlists],
    );

    const electives = useMemo(() => {
        if (!cohort) return [];

        return courses
            .flatMap(c => {
                if (c.exam) return null;
                if (requiredStreamsPackIds.includes(c.localePack.id)) return null;

                if (cohort.streamLocalePacksInfo[c.localePack.id]?.elective !== true) return null;
                const electiveSmartcases = c.lessons.filter(l => l.assessment === true);
                if (!electiveSmartcases.length) return null;

                return { course: c.title, smartcases: electiveSmartcases };
            })
            .filter(v => !!v);
    }, [cohort, courses, requiredStreamsPackIds]);

    return {
        playlistsAndSpecializations,
        assessmentCourses,
        nonPlaylistRequiredCourses,
        electives,
        foundationsPlaylist,
        includeFoundationsInGrades,
        ...projectsAndCapstones,
    };
};

export const useGradesData = () => {
    const user = useCurrentUser();
    const cohort = getCohort(user);

    const {
        playlistsAndSpecializations,
        projectsByPlaylists,
        capstones,
        assessmentCourses,
        nonPlaylistRequiredCourses,
        electives,
        foundationsPlaylist,
        includeFoundationsInGrades,
    } = useGetContent();

    const { lessonProgress, projectProgress, streamProgress: courseProgress } = useContentProgress();

    const [playlistsWithGrades, specializationsWithGrades] = useMemo(() => {
        if (!cohort || !user) return [[], []];

        return playlistsAndSpecializations.reduce(
            ([playlistGrades, specializationGrades], p) => {
                if (!p) return [playlistGrades, specializationGrades];

                const coursesForPlaylist = p.streamEntries
                    .map(se => assessmentCourses.find(c => c.localePack.id === se.localePackId) as Stream)
                    .filter(v => !!v);

                const objectWithGrades = {
                    title: p.title,
                    image:
                        p.localePackId === foundationsPlaylist?.localePackId
                            ? null
                            : coursesForPlaylist.filter(c => !c.exam).at(-1)?.image,
                    smartcases: coursesForPlaylist
                        .filter(c => !c.exam)
                        .flatMap(c =>
                            c.lessons.map(l => {
                                const progress = lessonProgress.find(lp => lp.localePackId === l.localePack.id);

                                // If we are not including foundations in the user's grades, we won't show the foundations playlist on the grades
                                // page, but we will show smartcases that are part of a foundations course that are required in other playlists
                                // we just want to add an asterisk to the title so we can reference it to ensure users these will not count
                                // towards their final grade
                                const addAsterisk =
                                    !includeFoundationsInGrades &&
                                    !!cohort.foundationsLessonStreamLocalePackIds.find(id => id === c.localePack.id);

                                return {
                                    title: l.title,
                                    course: addAsterisk ? (
                                        <div>
                                            {c.title}
                                            <p className="ms-[1px] inline-block pt-[1px] align-middle text-lg font-semibold leading-[0] text-beige-beyond-dark">
                                                *
                                            </p>
                                        </div>
                                    ) : (
                                        c.title
                                    ),
                                    score: formatScore(progress),
                                    completedAt: progress?.completedAt
                                        ? formattedUserFacingMonthDayYearMedium(new Date(progress.completedAt * 1000))
                                        : null,
                                    complete: progress?.complete ?? false,
                                    started: !!progress,
                                    localePackId: l.localePack.id,
                                };
                            }),
                        ),
                    exams: coursesForPlaylist
                        .filter(c => !!c.exam)
                        .map(e => {
                            const examProgress = courseProgress.find(lp => lp.localePackId === e.localePack.id);
                            return {
                                title: e.title,
                                completedAt: examProgress?.completedAt
                                    ? formattedUserFacingMonthDayYearMedium(new Date(examProgress.completedAt * 1000))
                                    : null,
                                score: formatScore(examProgress),
                                started: !!examProgress,
                            };
                        }),
                    projects:
                        projectsByPlaylists[p.localePackId]?.map(project => {
                            const progress = projectProgress.find(
                                pp => pp.requirementIdentifier === project.requirementIdentifier,
                            );
                            const score = progress?.score ?? null;

                            return {
                                title: project.title,
                                'Rubric Score': score ? String(score) : null,
                                status: getProjectStatus(progress, project.projectType, cohort!),
                                started: !!progress,
                            };
                        }) || [],
                } as PlaylistsWithGrades;

                const complete =
                    !!objectWithGrades.smartcases.length &&
                    objectWithGrades.smartcases.every(s => !!s.complete) &&
                    objectWithGrades.exams.every(e => !!e.completedAt) &&
                    objectWithGrades.projects.every(pr => pr.status !== '\u2014');

                const started =
                    objectWithGrades.smartcases?.some(s => !!s.started) ||
                    objectWithGrades.exams?.some(e => !!e.started) ||
                    objectWithGrades.projects?.some(pr => !!pr.started);

                objectWithGrades.complete = complete;
                objectWithGrades.started = started;

                if (cohort?.specializationPlaylistPackIds?.includes(p.localePackId)) {
                    specializationGrades.push(objectWithGrades);
                } else {
                    playlistGrades.push(objectWithGrades);
                }

                return [playlistGrades, specializationGrades];
            },
            [[] as PlaylistsWithGrades[], [] as PlaylistsWithGrades[]],
        );
    }, [
        cohort,
        user,
        playlistsAndSpecializations,
        foundationsPlaylist?.localePackId,
        projectsByPlaylists,
        assessmentCourses,
        lessonProgress,
        includeFoundationsInGrades,
        courseProgress,
        projectProgress,
    ]);

    const capstoneWithGrades = useMemo(() => {
        const values = capstones.map(c => {
            const progress = projectProgress.find(pp => pp.requirementIdentifier === c.requirementIdentifier);
            const score = progress?.score ?? null;

            return {
                Title: c.title,
                'Rubric Score': score ? String(score) : null,
                status: getProjectStatus(progress, c.projectType, cohort!),
                started: !!progress,
            };
        });
        const complete = values.every(v => v.status !== '\u2014');
        const started = values.some(v => v.started);
        return { complete, started, values };
    }, [capstones, cohort, projectProgress]);

    const otherRequirementsWithGrades = useMemo(() => {
        const values = nonPlaylistRequiredCourses
            .filter(c => !c.exam)
            .flatMap(c =>
                c.lessons
                    .filter(l => !!l.assessment)
                    .map(l => {
                        const progress = lessonProgress.find(lp => lp.localePackId === l.localePack.id);

                        return {
                            title: l.title,
                            course: c.title,
                            score: formatScore(progress),
                            completedAt: progress?.completedAt
                                ? formattedUserFacingMonthDayYearMedium(new Date(progress.completedAt * 1000))
                                : null,
                            complete: progress?.complete ?? false,
                            started: !!progress,
                        };
                    }),
            );
        const started = values.some(v => !!v.started);
        const complete = values.every(v => !!v.complete);

        return { started, complete, values };
    }, [lessonProgress, nonPlaylistRequiredCourses]);

    const electivesWithGrades = useMemo(
        () =>
            electives.flatMap(e =>
                e.smartcases
                    .map(sc => {
                        const progress = lessonProgress.find(lp => lp.localePackId === sc.localePack.id);
                        if (!progress) return null;

                        return {
                            course: e.course,
                            title: sc.title,
                            score: formatScore(progress),
                            completedAt: progress?.completedAt
                                ? formattedUserFacingMonthDayYearMedium(new Date(progress.completedAt * 1000))
                                : null,
                            complete: progress?.complete ?? false,
                            started: !!progress,
                            localePackId: sc.localePack.id,
                        };
                    })
                    .filter(v => !!v),
            ),
        [electives, lessonProgress],
    );

    const refundEligibilityInfo = useMemo(() => {
        const refundEntitlement = getRefundEntitlement(user);
        if (!isAssessmentPolicyWithCurriculumStarted(refundEntitlement)) return null;

        const { numExamsForPolicy, numSmartcasesForPolicy, numRequiredProjectsForPolicy, refundLevelAssessmentRanges } =
            refundEntitlement.metadata;

        const initialValue = {
            smartcases: { completed: 0, required: numSmartcasesForPolicy },
            exams: { completed: 0, required: numExamsForPolicy },
            projects: {
                completed: 0,
                required: capstoneWithGrades.values.length
                    ? Math.max(numRequiredProjectsForPolicy - 1, 0)
                    : numRequiredProjectsForPolicy,
            },
            capstone: { completed: 0, required: capstoneWithGrades.values.length ? 1 : 0 },
            refundLevelAssessmentRanges,
        };

        const completedSpecializationSmartcases =
            specializationsWithGrades.flatMap(s => s.smartcases?.filter(sc => sc.complete))?.length || 0;

        const completedSpecializationExams =
            specializationsWithGrades.flatMap(s => s.exams?.filter(sc => !!sc.completedAt))?.length || 0;

        initialValue.smartcases.completed = completedSpecializationSmartcases;

        initialValue.exams.completed = completedSpecializationExams;

        return playlistsWithGrades.reduce(({ smartcases, exams, projects, capstone }, gradeObject, i, arr) => {
            const { smartcases: _sc, exams: _exams, projects: _projects } = gradeObject;

            const completedSmartcases = _sc?.filter(sc => sc.complete)?.length ?? 0;
            const completedExams = _exams?.filter(e => !!e.completedAt)?.length ?? 0;
            const completedProjects = _projects?.filter(p => p.status !== '\u2014')?.length ?? 0;

            if (i === arr.length - 1) {
                const completedOtherRequiredSmartcases =
                    otherRequirementsWithGrades.values.filter(v => v.complete)?.length ?? 0;

                const completedCapstone = capstoneWithGrades.values?.every(v => v.status !== '\u2014');

                smartcases.completed += completedOtherRequiredSmartcases;

                capstone.completed += completedCapstone ? 1 : 0;
            }

            return {
                smartcases: {
                    completed: smartcases.completed + completedSmartcases,
                    required: smartcases.required,
                },
                exams: {
                    completed: exams.completed + completedExams,
                    required: exams.required,
                },
                projects: {
                    completed: projects.completed + completedProjects,
                    required: projects.required,
                },
                capstone,
                refundLevelAssessmentRanges,
            };
        }, initialValue);
    }, [
        capstoneWithGrades.values,
        otherRequirementsWithGrades.values,
        playlistsWithGrades,
        specializationsWithGrades,
        user,
    ]);

    return {
        playlistsWithGrades,
        specializationsWithGrades,
        otherRequirementsWithGrades,
        capstoneWithGrades,
        electivesWithGrades,
        refundEligibilityInfo,
        foundationsPlaylist,
        includeFoundationsInGrades,
    };
};
